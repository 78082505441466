import BioDataForm from '../BioDataForm';

const Home = () => {
  return (
    <div>
      <BioDataForm />
    </div>
  );
};
export default Home;
